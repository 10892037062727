// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navPathsFunc, locale } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import SeveritySection from '../reusables/SeveritySection';
import IconArray from '../IconArray/IconArray';
import './Moderate.scss';
import pageAudioActions from '../../actions/audioActions';
import type { SpectrumPageProps } from '../../lib/types';

const pageName = 'Moderate';

const [previous, next] = navPathsFunc(pageName);

const Moderate = ({ chances, content, updateTrack, pauseAudio }: SpectrumPageProps) => {
  useEffect(() => {
    updateTrack('ModDis');

    return pauseAudio;
  }, []);

  return (
    <div className={`background severity ${pageName.toLowerCase()}`}>
      <div className="content">
        <article>
          <h2 className="coloredHeading">{content[pageName].header}</h2>
          <div className="show">
            <IconArray mild={chances.mild} moderate={chances.moderate} severe={chances.severe} nonSurvival={chances.nonsurvival} isSpectrumPage distribution="linear" />
          </div>
          <SeveritySection index={1} section={content[pageName].disabilities} listType="disabilities" />
          <div className="spacer" />
          <SeveritySection index={2} section={content[pageName].cares} listType="cares" />
          {
            content[pageName].others && [
              <div className="spacer" />,
              <SeveritySection index={3} section={content[pageName].others} listType="others" />
            ]
          }
        </article>

        <div className="video-and-text">
          <div className="video">
            <iframe
              title="moderate-video"
              src={locale === 'es' ? 'https://bcove.video/3nkO8ul' : 'https://bcove.video/2yngH02'}
              allowFullScreen
              allow="autoplay; encrypted-media"
              width="480"
              height="270"
            />
          </div>
          <div className="video-text">
            <h3 className="coloredHeading">{content[pageName].story.heading}</h3>
            <p>{content[pageName].story.text}</p>
          </div>
        </div>

        <NavBar next={next} previous={previous} />

        <div className="top-right-corner">
          <h5 className="orange-text resuscitation">
            {content[pageName].type}
          </h5>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ chances, content }) => ({ chances, content });
const mapDispatchToProps = pageAudioActions;

export default connect(mapStateToProps, mapDispatchToProps)(Moderate);
