// @flow

import React from 'react';
import { connect } from 'react-redux';
import { markdown } from '../../lib/utils';
import './InstructionPage.scss';
import type { ContentShape } from '../../lib/types';

type InstructionPageProps = {
  page: string,
  content: ContentShape,
  children: React.Element<*>
}

const InstructionPage = ({ page, content, children }: InstructionPageProps) => {
  const cci = page === 'ComfortCareIntro';
  const ri = page === 'ResuscitationIntro';
  const classes = `background plain severity ${page.toLowerCase()}`;

  return (
    <div className={classes}>
      <div className="content">
        <article>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: markdown(content[page].markdown) }}
          />
        </article>
        { children }
        {
          (cci || ri) && (
            <div className="top-right-corner">
              <h5 className={`orange-text ${cci ? 'comfort-care' : 'resuscitation'}`}>
                {content[page].type}
              </h5>
            </div>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = ({ content }) => ({ content });

export default connect(mapStateToProps)(InstructionPage);
