// @flow

import React from 'react';

type RadioButtonProps = {
  index: number,
  yOrN: string,
  active: boolean,
  dim: boolean,
  radioClickHandler: void => void
}

const RadioButton = ({ index, active, yOrN = '', radioClickHandler, dim = false }: RadioButtonProps) => (
  <span className="radio-enlarge">
    <span
      className={`radio-button-custom ${dim ? 'dim' : ''}`}
      id={`${index}-${yOrN}`}
      role="radio"
      aria-checked={active}
      tabIndex={(index * 6)}
      onClick={() => radioClickHandler(yOrN)}
      onKeyPress={() => radioClickHandler(yOrN)}
    >
      {active ? <span className="inner-circle" /> : null}
    </span>
  </span>
);

export default RadioButton;
