// @flow

import React, { createRef } from 'react';
import { connect } from 'react-redux';
import pageAudioActions from '../../actions/audioActions';
import IconArray from '../IconArray/IconArray';
import { navPathsFunc, locale } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import './Outcomes.scss';
import type { SpectrumPageProps, DueDateObj } from '../../lib/types';

const [previous, next] = navPathsFunc('Outcomes');

type Props = SpectrumPageProps & {
  dueDateObj: DueDateObj
}
type State = {
  resGraphState: string,
  ccGraphState: string,
  isOutcomePageAudioLocked: boolean,
  isResultsAudioUnlocked: boolean,
}

class Outcomes extends React.Component<Props, State> {
  state = {
    resGraphState: '',
    ccGraphState: '',
    isOutcomePageAudioLocked: false,
    isResultsAudioUnlocked: false
  };

  audioManagerPlayButtonRef = createRef();

  audioManagerPauseButtonRef = createRef();

  resAudioRef = createRef();

  ccAudioRef = createRef();

  componentDidMount() {
    const { updateTrack, dueDateObj } = this.props;
    const weeks = dueDateObj.value.slice(0, 2);

    updateTrack(`Outcomes${weeks}wk`);
  }

  componentWillUnmount() {
    this.props.pauseAudio();
  }

  ResGraphButtonClickHandler = () => {
    const { isResultsAudioUnlocked } = this.state;
    const weeks = this.props.dueDateObj.value.slice(0, 2);

    this.setState({ resGraphState: '' });

    if (isResultsAudioUnlocked) this.setState({ isOutcomePageAudioLocked: true });

    this.audioManagerPauseButtonRef.current.click();
    this.ccAudioRef.current.pause();
    this.ccAudioRef.current.src = `/audios/${locale}/CC${weeks}wk.mp3`;

    setTimeout(() => {
      this.setState({ resGraphState: 'show', });
      if (isResultsAudioUnlocked) this.resAudioRef.current.play();
    }, 50);
  }

  CCGraphButtonClickHandler = () => {
    const { isResultsAudioUnlocked } = this.state;
    const weeks = this.props.dueDateObj.value.slice(0, 2);

    this.setState({ ccGraphState: '' });

    if (isResultsAudioUnlocked) this.setState({ isOutcomePageAudioLocked: true });

    this.audioManagerPauseButtonRef.current.click();
    this.resAudioRef.current.pause();
    this.resAudioRef.current.src = `/audios/${locale}/Resus${weeks}wk.mp3`;

    setTimeout(() => {
      this.setState({ ccGraphState: 'show', });
      if (isResultsAudioUnlocked) this.ccAudioRef.current.play();
    }, 50);
  }

  render() {
    const { resGraphState, ccGraphState, isOutcomePageAudioLocked } = this.state;
    const { chances, dueDateObj, content } = this.props;
    const weeks = dueDateObj.value.slice(0, 2);

    const weeksTextObj = {
      '22': content.Survey.questionList[0].dropdownList[1],
      '23': content.Survey.questionList[0].dropdownList[2],
      '24': content.Survey.questionList[0].dropdownList[3],
      '25': content.Survey.questionList[0].dropdownList[4],
    };

    const option1body = content.Outcomes.option1.body.replace('{weeks}', dueDateObj.value);
    const totalSurvival = chances.mild + chances.moderate + chances.severe;

    return (
      <div className="background plain2">
        <div className="outcomes content">
          <article>
            <h2>{content.Outcomes.header}</h2>
            <div className="container">
              <div className={`${resGraphState} left resuscitation`}>
                <section>
                  <h3>{content.Outcomes.option1.heading}</h3>
                  <p>{option1body}</p>
                  <button className={resGraphState ? 'pressedButton' : 'green-button'} type="button" onClick={this.ResGraphButtonClickHandler}>
                    {content.Outcomes.result}
                  </button>
                  <audio
                    ref={this.resAudioRef}
                    preload="auto"
                    onEnded={() => {
                      this.setState({ isOutcomePageAudioLocked: false });
                    }}
                  >
                    <source src={`/audios/${locale}/Resus${weeks}wk.mp3`} />
                  </audio>
                </section>

                <div className="outcomes-intro">
                  <h4>{`${content.Outcomes.option1.results.heading} ${weeksTextObj[weeks]}`}</h4>
                </div>

                <div className="graph">
                  <IconArray
                    mild={chances.mild}
                    moderate={chances.moderate}
                    severe={chances.severe}
                    nonSurvival={chances.nonsurvival}
                    distribution="linear"
                  >
                    <h6 className="total">{`${totalSurvival} ${content.Outcomes.option1.results.legend.total}`}</h6>
                  </IconArray>
                </div>
              </div>
              <div className={`${ccGraphState} right comfort-care`}>
                <section>
                  <h3>{content.Outcomes.option2.heading}</h3>
                  <p>{content.Outcomes.option2.body}</p>
                  <button className={ccGraphState ? 'pressedButton' : 'green-button'} type="button" onClick={this.CCGraphButtonClickHandler}>
                    {content.Outcomes.result}
                  </button>
                  <audio
                    ref={this.ccAudioRef}
                    preload="auto"
                    onEnded={() => {
                      this.setState({ isOutcomePageAudioLocked: false });
                    }}
                  >
                    <source src={`/audios/${locale}/CC${weeks}wk.mp3`} />
                  </audio>
                </section>

                <div className="outcomes-intro">
                  <h4>{`${content.Outcomes.option2.results.heading} ${weeksTextObj[weeks]}`}</h4>
                </div>

                <div className="graph">
                  <IconArray
                    mild={0}
                    moderate={0}
                    severe={0}
                    nonSurvival={100}
                    distribution="linear"
                  />
                </div>
              </div>
            </div>
          </article>

          <NavBar
            next={next}
            previous={previous}
            audioManagerPauseButtonRef={this.audioManagerPauseButtonRef}
            audioManagerPlayButtonRef={this.audioManagerPlayButtonRef}
            locked={!!isOutcomePageAudioLocked}
            additionalClickHandler={() => this.setState({ isResultsAudioUnlocked: true })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ chances, dueDateObj, content }) => ({ chances, dueDateObj, content });
const mapDispatchToProps = pageAudioActions;

export default connect(mapStateToProps, mapDispatchToProps)(Outcomes);
