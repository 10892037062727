// @flow

const initialState = {
  choice: '',
  input: '',
  value: '',
  // choice: "weeksPregnant",
  // input: "25 weeks to 25 weeks and 6 days",
  // value: "25 weeks"
};

const dueDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DUE_DATE_CHANGE':
      return {
        choice: 'dueDate',
        input: action.input,
        value: action.value,
      };
    case 'WEEKS_PREGNANT_CHANGE':
      return {
        choice: 'weeksPregnant',
        input: action.input,
        value: action.value,
      };

    case 'RESET_APP':
      return initialState;

    case 'RESET_CHANCES':
      return initialState;

    default:
      return state;
  }
};

export default dueDateReducer;
