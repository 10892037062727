const initialState = {
  isPlaying: false,
  audioName: null
};

const audioToPlay = (state = initialState, action) => {
  switch (action.type) {
    case 'PLAY_OR_PAUSE':
      return {
        ...state,
        isPlaying: action.value,
      };

    case 'UPDATE_TRACK':
      return {
        ...state,
        audioName: action.value
      };

    default:
      return state;
  }
};

export default audioToPlay;
