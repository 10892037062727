// @flow

export type textInputAction = {
  type: string,
  value: string,
  number: number,
}

export type sliderChangeAction = {
  type: string,
  value: number,
  index: number,
}

export default {
  textInputChange: (value: string, number: number): textInputAction => ({
    type: 'VALUES1_TEXT_INPUT_CHANGE',
    value,
    number
  }),
  sliderChange: (value: number, index: number): sliderChangeAction => ({
    type: 'SLIDER_SCORE_CHANGE',
    value,
    index,
  }),
};
