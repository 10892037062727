// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pageAudioActions from '../../actions/audioActions';
import OptionSection from '../reusables/OptionSection';
import { navPathsFunc } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import './Options.scss';
import type { PageProps } from '../../lib/types';

const [previous, next] = navPathsFunc('Options');

const Options = ({ content, updateTrack, pauseAudio }: PageProps) => {
  useEffect(() => {
    updateTrack('Options');

    return pauseAudio;
  }, []);

  return (
    <div className="background plain">
      <div className="options content">
        <article>
          <h2>{content.Options.header}</h2>
          <p className="head-note">{content.Options.body.text1}</p>
          <p className="head-note">{content.Options.body.text2}</p>
          <OptionSection
            section={content.Options.body.option1}
          />
          <OptionSection
            section={content.Options.body.option2}
          />
        </article>

        <footer className="foot-note push-right">{content.Options.footer}</footer>

        <NavBar
          next={next}
          previous={previous}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ content }) => ({ content });
const mapDispatchToProps = pageAudioActions;

export default connect(mapStateToProps, mapDispatchToProps)(Options);
