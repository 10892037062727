// @flow

// builds an array of 100 strings
export const buildArray100 = (mild: number, moderate: number, severe: number, nonSurvival: number): number[] => {
  let dist = [
    ...Array(mild).fill('mild'),
    ...Array(moderate).fill('moderate'),
    ...Array(severe).fill('severe'),
    ...Array(nonSurvival).fill('nonSurvival')
  ];

  if (dist.length < 100) { dist = [...dist, ...Array(100 - dist.length).fill('nonSurvival')]; }

  if (dist.length > 100) { dist = dist.slice(0, 100); }

  return dist;
};

export const computeRowCount = (num: number, first: boolean): number => {
  const rows = Math.floor(num / 10);
  const remainder = (num % 10);
  if (first && remainder === 0) { return rows; }
  return rows + 1;
};

export const distByRow = (numRows: number, count: number, fillString: string): string[] => {
  let newDist = [];
  for (let row = 0; row < numRows; row += 1) {
    const baseNum = Math.floor(count / numRows);
    const current = (row === numRows - 1) ? count - (baseNum * (numRows - 1)) : baseNum;
    newDist = [...newDist, ...Array(current).fill(fillString), ...Array(10 - current).fill('nonSurvival')];
  }
  return newDist;
};
