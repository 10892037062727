// @flow

import React from 'react';
import { connect } from 'react-redux';
import { navPathsFunc, locale } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import SeveritySection from '../reusables/SeveritySection';
import IconArray from '../IconArray/IconArray';
import './NonSurvival.scss';
import pageAudioActions from '../../actions/audioActions';
import type { SpectrumPageProps } from '../../lib/types';

type NonSurvivalState = { activeAccordion: number }

const pageName = 'NonSurvival';

const [previous, next] = navPathsFunc(pageName);

class NonSurvival extends React.Component<SpectrumPageProps, NonSurvivalState> {
  state = { activeAccordion: 1 };

  componentDidMount() {
    this.props.updateTrack('Non-Surv');
  }

  componentWillUnmount() {
    this.props.pauseAudio();
  }

  updateActiveAccordion = num => { this.setState({ activeAccordion: num }); }

  render() {
    const { chances, content } = this.props;
    const { activeAccordion } = this.state;
    const accordionText = content[pageName].more;

    return (
      <div className={`background severity ${pageName.toLowerCase()}`}>
        <div className="content">
          <article>
            <h2 className="coloredHeading">{content[pageName].header}</h2>
            <div className="show">
              <IconArray mild={chances.mild} moderate={chances.moderate} severe={chances.severe} nonSurvival={chances.nonsurvival} isSpectrumPage distribution="linear" />
            </div>

            <SeveritySection
              index={1}
              section={content[pageName].disabilities}
              listType="disabilities"
              updateActiveAccordion={this.updateActiveAccordion}
              activeAccordion={activeAccordion}
              accordion
              accordionText={accordionText}
            />

            <div className="spacer" />

            <SeveritySection
              index={2}
              section={content[pageName].cares}
              listType="cares"
              updateActiveAccordion={this.updateActiveAccordion}
              activeAccordion={activeAccordion}
              accordion
              accordionText={accordionText}
            />

            {
              content[pageName].others && [
                <div key="spacer" className="spacer" />,
                <SeveritySection
                  key="others"
                  index={3}
                  section={content[pageName].others}
                  listType="others"
                  updateActiveAccordion={this.updateActiveAccordion}
                  activeAccordion={activeAccordion}
                  accordion
                  accordionText={accordionText}
                />
              ]
            }
          </article>

          <div className="video-and-text">
            <div className="video">
              <iframe
                title="non-survival-video"
                src={locale === 'es' ? 'https://bcove.video/30vIehD' : 'https://bcove.video/2y4xGnB'}
                allowFullScreen
                allow="autoplay; encrypted-media"
                width="480"
                height="270"
              />
            </div>
            <div className="video-text">
              <h3 className="coloredHeading">{content[pageName].story.heading}</h3>
              <p>{content[pageName].story.text}</p>
            </div>
          </div>

          <NavBar next={next} previous={previous} />

          <div className="top-right-corner">
            <h5 className="orange-text resuscitation">
              {content[pageName].type}
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ chances, content }) => ({ chances, content });

const mapDispatchToProps = pageAudioActions;

export default connect(mapStateToProps, mapDispatchToProps)(NonSurvival);
