// @flow

import React from 'react';
import { markdown } from '../../lib/utils';
// import rCircleIcon from '../../images/R_CircleIcon.png';
// import cCircleIcon from '../../images/C_CircleIcon.png';

type OptionSectionProps = {
  section: {
    superHeading: string,
    heading: string,
    markdown: string,
  }
}

const OptionSection = ({ section }: OptionSectionProps) => {
  const iconClass = section.superHeading === 'OPTION 1' ? 'resuscitation' : 'comfort-care';

  return (
    <section>
      <h3>{section.superHeading}</h3>
      <h4 className={`orange-text ${iconClass}`}>
        {section.heading}
      </h4>
      <div
        className="markdown push-right"
        dangerouslySetInnerHTML={{ __html: markdown(section.markdown) }}
      />
    </section>
  );
};

export default OptionSection;
