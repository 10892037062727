// @flow

import React from 'react';

const SummaryPrintOnly = ({ header }: { header: string }) => (
  <div className="show-print-only">
    <h6>{header}</h6>
    <hr className="rule" />
    <hr className="rule" />
    <hr className="rule" />
    <hr className="rule" />
    <hr className="rule" />
  </div>
);

export default SummaryPrintOnly;
