// @flow

import React from 'react';
import { markdown } from '../../lib/utils';

type SeveritySectionProps = {
  accordion: boolean,
  section: {
    heading: string,
    list?: string[],
    markdown?: string,
  },
  activeAccordion: number,
  updateActiveAccordion: (num: number) => void,
  listType: string,
  index: number,
  accordionText: string,
}

type ListGeneratorProps = {
  arr: strings[],
  listType: string,
  Component: React.Element<*>
}

const ListGenerator = ({ arr, Component }: ListGeneratorProps) => (
  arr.map((item: string) => (
    <Component key={item}>
      {item}
    </Component>
  ))
);

const SeveritySection = ({
  accordion = false,
  section = null,
  activeAccordion = 0,
  updateActiveAccordion = null,
  listType,
  index,
  accordionText = ''
}: SeveritySectionProps) => {
  let UI = null;
  if (section) {
    if (section.markdown) {
      UI = (
        <div className="markdown" dangerouslySetInnerHTML={{ __html: markdown(section.markdown) }} />
      );
    } else {
      UI = (
        <div>
          {
            accordion && (
              <span
                role="button"
                tabIndex={index}
                className={index === activeAccordion ? 'inactive' : 'accordion coloredHeading'}
                onClick={() => updateActiveAccordion(index)}
                onKeyPress={() => updateActiveAccordion(index)}
              >
                { accordionText }
              </span>
            )
          }
          <div className={index === activeAccordion ? null : 'inactive'}>
            <ListGenerator
              Component={({ children }) => <p>{children}</p>}
              arr={section.list}
              listType={listType}
            />
          </div>
        </div>
      );
    }
  }

  return (
    section && (
      <section>
        <h3>{section.heading}</h3>
        { UI }
      </section>
    )
  );
};

export default SeveritySection;
