// @flow

import React from 'react';
import { connect } from 'react-redux';
import sliderActions from '../../actions/sliderActions';
import type { sliderChangeAction } from '../../actions/sliderActions';
import RadioButton from './RadioButton';

type ValuesQuestionProps = {
  index: number,
  str: string,
  valuesScoreArr: number[],
  sliderChange: (value: number, index: number) => sliderChangeAction
}

const ValuesQuestion = ({ index, str, valuesScoreArr, sliderChange }: ValuesQuestionProps) => {
  const dunnoRadioClickHandler = () => { sliderChange(6, index); };

  const sliderValue = valuesScoreArr[index];

  return (
    <div className="values-question">
      { str && <h6>{ str }</h6> }
      <div className="slider-container">
        <div className="ticks">
          {
            [1, 2, 3, 4, 5].map(item => (
              <RadioButton
                key={item}
                id={`radio-${item}`}
                index={item}
                active={valuesScoreArr[index] === item}
                radioClickHandler={() => { sliderChange(item, index); }}
              />
            ))
          }
        </div>
      </div>

      <div className="I-dont-know-container">
        <RadioButton
          id={`radio-${index}`}
          index={index}
          active={sliderValue === 6}
          radioClickHandler={dunnoRadioClickHandler}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ valuesScoreArr }) => ({ valuesScoreArr });
const mapDispatchToProps = sliderActions;

export default connect(mapStateToProps, mapDispatchToProps)(ValuesQuestion);
