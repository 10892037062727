export default [
  'Intro',
  'Options',
  // 'Instructions',
  'Survey',
  'Outcomes',
  'ResuscitationIntro',
  'Mild',
  'Moderate',
  'Severe',
  'NonSurvival',
  'ComfortCareIntro',
  'ComfortCare',
  'PersonalValues',
  'ValuesSurvey1',
  'ValuesSurvey2',
  'Summary',
  'Preface',
];
