// @flow

import React from 'react';

type PrefaceState = { mode: string }

class Preface extends React.Component<void, PrefaceState> {
  state = { mode: 'start' };

  openGoals = () => {
    this.setState({ mode: 'end' });
    const win = window.open(window.location.origin, '_blank');
    win.focus();
  }

  render() {
    const { mode } = this.state;

    return (
      <main style={{ margin: '60px auto', backgroundColor: 'wheat' }}>
        {
          mode === 'start'
            ? (
              <div>
                <p
                  style={{ display: 'block', fontSize: '1.625rem', margin: '60px' }}
                  htmlFor="start-button"
                >
                  Click button below to start the GOALS tool.
                </p>
                <button
                  id="start-button"
                  type="button"
                  onClick={this.openGoals}
                  style={{ display: 'block', margin: '60px auto', fontSize: '1.125rem', padding: '0.5rem 1rem' }}
                >
                  Start
                </button>
              </div>
            ) : (
              <p style={{ padding: '40px' }}>Please close this tab and return to the survey.</p>
            )
        }
      </main>
    );
  }
}

export default Preface;
