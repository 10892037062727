// @flow

import React from 'react';
import { connect } from 'react-redux';
import { markdown } from '../../lib/utils';
import type { ContentShape } from '../../lib/types';

type HandsPageProps = {
  pageName: string,
  content: ContentShape,
  children: React.Element<*>
}

const HandsPage = ({ pageName, content, children }: HandsPageProps) => (
  <div className="hands background">
    <div className={`content ${pageName.toLowerCase()}`}>
      <article>
        <h2>{content[pageName].header}</h2>
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: markdown(content[pageName].body) }}
        />
      </article>
      { children }
      {/* {
        content[pageName].footer
          ? (
            <footer className="footer white-bubble">
              &nbsp;&nbsp;<b>{content[pageName].footer.bold}</b>{content[pageName].footer.normal}&nbsp;&nbsp;
            </footer>
          ) : null
      } */}
    </div>
  </div>
);

const mapStateToProps = ({ content }) => ({ content });

export default connect(mapStateToProps)(HandsPage);
