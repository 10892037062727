// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pageAudioActions from '../../actions/audioActions';
import { navPathsFunc } from '../../lib/utils';
import HandsPage from '../pageAbstraction/HandsPage';
import NavBar from '../reusables/NavBar';
import type { PageProps } from '../../lib/types';
import './Intro.scss';

const pageName = 'Intro';

// eslint-disable-next-line no-unused-vars
const [_prev, next] = navPathsFunc(pageName);

const Intro = ({ updateTrack, pauseAudio }: PageProps) => {
  useEffect(() => {
    updateTrack('Intro');

    return pauseAudio;
  }, []);

  return (
    <HandsPage pageName={pageName} >
      <NavBar next={next} />
    </HandsPage>
  );
};

const mapDispatchToProps = pageAudioActions;

export default connect(null, mapDispatchToProps)(Intro);
