// @flow

import React from 'react';
import NavBar from '../reusables/NavBar';
import ValuesQuestion from '../reusables/ValuesQuestion';
import { markdown, navPathsFunc } from '../../lib/utils';
import './ValuesSurveyPage.scss';
import type { ValuesSurveyShape } from '../../lib/types';

type ValuesSurveyPageProps = ValuesSurveyShape & {
  valuesSurveyPageNum: number,
}

const ValuesSurveyPage = ({ values1TextArr = [], textInputChange, valuesSurveyPageNum, content, children }: ValuesSurveyPageProps) => {
  const componentName = `ValuesSurvey${valuesSurveyPageNum}`;
  const lowerCaseComponentName = componentName.toLowerCase();
  const { questionList } = content[componentName];
  const questionsArr = valuesSurveyPageNum === 1 ? [...questionList, ...values1TextArr] : questionList;
  const continueIndex = valuesSurveyPageNum === 1 ? 0 : content.ValuesSurvey1.questionList.length + values1TextArr.length;
  const [previous, next] = navPathsFunc(componentName);

  return (
    <div className={`background plain3 valuessurvey ${lowerCaseComponentName}`}>
      <div className="content">
        <article>
          <h2>{content[componentName].header}</h2>
          <div
            className="head-note markdown"
            dangerouslySetInnerHTML={{ __html: markdown(content[componentName].markdown) }}
          />
        </article>
        { children }
        <div className="container" >
          <div className="left" >
            {
              questionsArr.map((item, i) => (
                <div className="left-item-container" key={i}>
                  {
                    i < questionList.length
                      ? <h5 className={`${lowerCaseComponentName}-items`}>{item}</h5>
                      : <input className="valuessurvey1-items" value={values1TextArr[i - questionList.length]} onChange={e => textInputChange(e.target.value, i - questionList.length)} maxLength="70" />
                  }
                </div>
              ))
            }
          </div>
          <div className="right" >
            { questionsArr.map((item, i) => (<ValuesQuestion key={i} index={i + continueIndex} str="" />)) }
          </div>
        </div>
        <NavBar next={next} previous={previous} /*  grayNext={!this.NavEnabler(conten)} */ />
      </div>
    </div>
  );
};

export default ValuesSurveyPage;
