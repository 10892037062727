// @flow

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { locale } from '../../lib/utils';
import pageAudioActions from '../../actions/audioActions';
import playIcon from '../../images/audio_play.svg';
import pauseIcon from '../../images/audio_pause.svg';

type AudioProps = {
  audioName: string,
  isPlaying: boolean,
  audioManagerPauseButtonRef: any,
  audioManagerPlayButtonRef: any,
  playOrPauseAudio: boolean => void,
  locked: boolean,
  additionalClickHandler: any => void | undefined
}

const AudioManager = ({
  audioName,
  isPlaying,
  playOrPauseAudio,
  audioManagerPauseButtonRef = null,
  audioManagerPlayButtonRef = null,
  locked,
  additionalClickHandler
}: AudioProps) => {
  const audioRef = useRef();

  useEffect(() => {
    if (locked) audioRef.current.src = `/audios/${locale}/${audioName}.mp3`;
  }, [locked]);

  if (!audioName) return null;

  return (
    <div className="AudioManager">
      <audio
        ref={audioRef}
        preload="auto"
        onEnded={() => {
          playOrPauseAudio(false);
        }}
      >
        <source src={`/audios/${locale}/${audioName}.mp3`} />
      </audio>
      <button
        ref={audioManagerPauseButtonRef}
        className={`pause ${isPlaying && !locked && 'visible'}`}
        type="button"
        onClick={() => {
          playOrPauseAudio(false);
          audioRef.current.pause();
        }}
      >
        <img src={pauseIcon} alt="pause audio" />
      </button>

      <button
        type="button"
        ref={audioManagerPlayButtonRef}
        className={`play ${!isPlaying && !locked && 'visible'}`}
        onClick={() => {
          playOrPauseAudio(true);
          audioRef.current.play();
          if (additionalClickHandler) additionalClickHandler();
        }}
      >
        <img src={playIcon} alt="play audio" />
      </button>
    </div>
  );
};

const mapStateToProps = ({ audio: { isPlaying, audioName } }) => ({ isPlaying, audioName });
const mapDispatchToProps = {
  ...pageAudioActions,
  playOrPauseAudio: (value: boolean) => ({
    type: 'PLAY_OR_PAUSE',
    value,
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioManager);
