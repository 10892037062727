// @flow

import showdown from 'showdown';
import stringsEN from '../strings/stringsEN.json';
import stringsES from '../strings/stringsES.json';
import pageOrder from './pageOrder';

export const locale = /^\/es/g.test(document.location.pathname) ? 'es' : 'en';

export const localeFunc = () => {
  if (locale === 'es') return stringsES;
  return stringsEN;
};

export const chanceArticle = locale === 'es' ? 'El ' : '';

export const navPathsFunc = currentPage => {
  const pageNum = pageOrder.indexOf(currentPage);
  return [pageOrder[pageNum - 1], pageOrder[pageNum + 1]];
};

const showdownConverter = new showdown.Converter();
export const markdown = (text: string) => showdownConverter.makeHtml(text);
