// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import sliderActions from '../../actions/sliderActions';
import pageAudioActions from '../../actions/audioActions';
import ValuesSurveyPage from '../pageAbstraction/ValuesSurveyPage';
import type { ValuesSurveyShape } from '../../lib/types';

const ValuesSurvey2 = ({ values1TextArr, textInputChange, content, updateTrack, pauseAudio }: ValuesSurveyShape) => {
  useEffect(() => {
    updateTrack('Values2');

    return pauseAudio;
  }, []);

  return (
    <ValuesSurveyPage
      valuesSurveyPageNum={2}
      values1TextArr={values1TextArr}
      textInputChange={textInputChange}
      content={content}
    >
      <div className="hints-and-scale">
        <div className="scale">
          <span className="scale-left">{content.ValuesSurvey2.scale[0]}</span>
          <span className="scale-right">{content.ValuesSurvey2.scale[1]}</span>
          <span className="scale-dont-know">{content.ValuesSurvey2.scale[2]}</span>
        </div>
        <div className="survey-scores">
          {
            [1, 2, 3, 4, 5].map(item => <span className="survey-score" key={`score-${item}`}>{item}</span>)
          }
        </div>
      </div>
    </ValuesSurveyPage>
  );
};

const mapStateToProps = ({ values1TextArr, content }) => ({ values1TextArr, content });
const mapDispatchToProps = { ...sliderActions, ...pageAudioActions };

export default connect(mapStateToProps, mapDispatchToProps)(ValuesSurvey2);
