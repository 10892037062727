// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import pageAudioActions from '../../actions/audioActions';
import { navPathsFunc, locale } from '../../lib/utils';
import IconArray from '../IconArray/IconArray';
import NavBar from '../reusables/NavBar';
import SummaryPrintOnly from '../reusables/SummaryPrintOnly';
import pageOrder from '../../lib/pageOrder';
import history from '../../lib/history';
import './Summary.scss';
import type { SpectrumPageProps } from '../../lib/types';

type SummaryProps = SpectrumPageProps & {
  valuesScoreArr: number[],
  values1TextArr: string[],
  resetApp: () => void
};

const printClickHandler = () => {
  try {
    document.execCommand('print', false, null);
  } catch (e) {
    window.print();
  }
};

const pageName = 'Summary';

const [previous] = navPathsFunc(pageName);

const Summary = ({
  chances,
  valuesScoreArr,
  values1TextArr,
  content,
  resetApp,
  updateTrack,
  pauseAudio,
  dueDateObj
}: SummaryProps) => {
  const exitClickHandler = () => {
    resetApp();
    history.push('/');
  };

  const { factorsScoreArr, qolScoreArr } = content.Summary;

  const sortedFactorsArr = content.ValuesSurvey1.questionList.concat(values1TextArr).map((item, i) => [item, valuesScoreArr[i]]).sort((a, b) => b[1] - a[1]);
  const qolArr = content.ValuesSurvey2.questionList.map((item, i) => [item, valuesScoreArr[i + sortedFactorsArr.length]]);
  const continuedIndex = content.ValuesSurvey1.questionList.length + values1TextArr.length;

  const weeks = dueDateObj.value.slice(0, 2);
  const weeksTextObj = {
    '22': content.Survey.questionList[0].dropdownList[1].replace('\n', ''),
    '23': content.Survey.questionList[0].dropdownList[2].replace('\n', ''),
    '24': content.Survey.questionList[0].dropdownList[3].replace('\n', ''),
    '25': content.Survey.questionList[0].dropdownList[4].replace('\n', ''),
  };
  const totalSurvival = chances.mild + chances.moderate + chances.severe;

  useEffect(() => {
    updateTrack('Summary');

    return pauseAudio;
  }, []);

  return (
    <div className="background plain3">
      <div className="summary content">
        <article>
          <div className="summary-headnote">
            <h2>{content.Summary.header}</h2>
            <p>{content.Summary.text}</p>
          </div>
        </article>

        <hr className="rule" />

        <div className="summary-outcomes">
          <h3>{content.Summary.outcomes.heading}</h3>
          <p>{content.Summary.outcomes.text}</p>
          <div className="spacer" />
          <div className="summary-ia-outcomes">
            <div className="left">
              <h5 className="summary-outcomes-text">{`${content.Outcomes.option1.results.heading} ${weeksTextObj[weeks]}`}</h5>
              <IconArray mild={chances.mild} moderate={chances.moderate} severe={chances.severe} nonSurvival={chances.nonsurvival} distribution="linear">
                <h6 className="total">{`${totalSurvival} ${content.Outcomes.option1.results.legend.total}`}</h6>
              </IconArray>
            </div>
            <div className="right">
              <h5 className="summary-outcomes-text">{`${content.Outcomes.option2.results.heading} ${weeksTextObj[weeks]}`}</h5>
              <IconArray mild={0} moderate={0} severe={0} nonSurvival={100} comfortCare distribution="linear" />
            </div>
          </div>
        </div>

        <div className="summary-choices-container">
          <h3>{content.Summary.personalValues.heading}</h3>
          <div className="summary-choices">
            <div className="summary-factors">
              <h4>{content.Summary.personalValues.factors.bold}</h4>
              {
                sortedFactorsArr.map(item => (
                  item[0].trim()
                    ? (
                      <p key={item}>
                        {item[0]}: &nbsp;<span>{factorsScoreArr[item[1]]}</span>
                      </p>
                    ) : null
                ))
              }
            </div>
            <div className="summary-quality-of-life">
              <h4>{content.Summary.personalValues.qualityOfLife.bold}</h4>
              {
                qolArr.map((item, i) => (
                  <p key={item}>
                    {item[0]}:<br /><span>{qolScoreArr[valuesScoreArr[i + continuedIndex]]}</span>
                  </p>
                ))
              }
            </div>
          </div>
        </div>

        <SummaryPrintOnly header={content.Summary.printOnlyInstruction} />

        <div className="footnote">
          <p>{content.Summary.footer}</p>
        </div>

        <div className="buttons hidden-print">
          <button className="print green-button" type="button" onClick={printClickHandler}>{content.Summary.print}</button>
          <button className="exit green-button" type="button" onClick={window.close}>
            {content.Summary.close}
          </button>
          <Link to={`/${locale}/${pageOrder[0].toLowerCase()}`}>
            <button className="exit green-button" type="button" onClick={exitClickHandler}>
              {content.Summary.restart}
            </button>
          </Link>
        </div>
        <NavBar previous={previous} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ dueDateObj, chances, valuesScoreArr, values1TextArr, content }) => ({ dueDateObj, chances, valuesScoreArr, values1TextArr, content });
const mapDispatchToProps = { ...pageAudioActions, resetApp: () => ({ type: 'RESET_APP' }) };

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
