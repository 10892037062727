// @flow

const initialState = ['', ''];

const values1TextReducer = (state = initialState, action) => {
  const manualQuestions = state.slice();

  switch (action.type) {
    case 'VALUES1_TEXT_INPUT_CHANGE':
      manualQuestions[action.number] = action.value;
      return manualQuestions;
    case 'RESET_APP':
      return initialState;
    default:
      return state;
  }
};

export default values1TextReducer;
