// @flow

import React from 'react';
import { connect } from 'react-redux';
import surveyActions from '../../actions/surveyActions';
import { locale } from '../../lib/utils';
import type { ContentShape } from '../../lib/types';
import type { SurveyAction } from '../../actions/surveyActions';

type SurveyQuestionProps = {
  index: number,
  inputType: string,
  dueDateObj: string,
  hidden: boolean,
  className: string,
  content: ContentShape,
  reduxActionType: 'weeksPregnant' | 'dueDateChange',
  weeksPregnantChange: (value: string) => SurveyAction,
  dueDateChange: (value: string) => SurveyAction,
}

const weeksEnglish = [
  '',
  '22 weeks',
  '23 weeks',
  '24 weeks',
  '25 weeks'
];

const weeksSpanish = [
  '',
  '22 semanas',
  '23 semanas',
  '24 semanas',
  '25 semanas'
];

const weeks = locale === 'es' ? weeksSpanish : weeksEnglish;

const SurveyQuestion = (props: SurveyQuestionProps) => {
  const inputChangeHandler = e => {
    const { value } = e.target;
    const { reduxActionType, weeksPregnantChange, dueDateChange } = props;

    if (reduxActionType === 'weeksPregnant') weeksPregnantChange(value);
    else if (reduxActionType === 'dueDate') dueDateChange(value);
  };

  const { index, inputType, dueDateObj, hidden, className, content } = props;

  return (
    <div className={`${className} ${hidden ? 'hidden question' : 'question'}`}>
      <p className="question-text">
        <b>{content.Survey.questionList[index].question}</b>
      </p>
      {
        inputType === 'dropdown' ? (
          <select value={dueDateObj.value || ''} tabIndex={(index * 2) + 1} onChange={inputChangeHandler}>
            {
              content.Survey.questionList[index].dropdownList.map((item, i) => (
                i === 0
                  ? <option value="" key="dropdown-0" disabled>{item}</option>
                  : <option value={weeks[i]} key={item}>{item}</option>
              ))
            }
          </select>
        ) : (
          <input
            defaultValue={dueDateObj.input || (new Date().toISOString().slice(0, 10))}
            className="survey-input"
            type={inputType}
            onChange={inputChangeHandler}
            // disabled={!y}
          />
        )
      }
      {
        inputType === 'date' && (
          <p className={dueDateObj.value !== '' ? 'error white-bubble wrong-date' : 'hidden'}>
            {dueDateObj.value === null ? content.Survey.error : content.Survey.gestationalAge.replace('X', dueDateObj.value)}
          </p>
        )
      }
    </div>
  );
};

const mapDispatchToProps = surveyActions;
const mapStateToProps = ({ dueDateObj, content }) => ({ dueDateObj, content });

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestion);

// dateValidator = value => {
//   const userInputDate = Date.parse(value);
//   const now = new Date().getTime();
//   if (!Number.isNaN(userInputDate)) {
//     const lowRange = new Date(now + 14 * 7 * 24 * 60 * 60 * 1000).getTime();
//     const highRange = new Date(now + 18 * 7 * 24 * 60 * 60 * 1000).getTime();
//     // console.log('lowRange', lowRange, 'highRange', highRange, 'userInputDate', userInputDate)
//     if (userInputDate < lowRange || userInputDate > highRange) {
//       return false;
//     }
//   }
//   return true;
// }
