// @flow

export default {
  updateTrack: (value: string) => ({
    type: 'UPDATE_TRACK',
    value
  }),
  pauseAudio: () => ({
    type: 'PLAY_OR_PAUSE',
    value: false,
  })
};
