// @flow

import React from 'react';
import Legend from './Legend';
import shuffleArray from '../../lib/shuffleArray';
import { distByRow, computeRowCount, buildArray100 } from './IconArrayUtils';
import './IconArray.scss';

type IconArrayProps = {
  mild: number,
  moderate: number,
  severe: number,
  nonSurvival?: number,
  distribution?: string,
  isSpectrumPage?: boolean,
  comfortCare?: boolean,
  children: React.Element<*> | undefined
};

const IconArray = (props: IconArrayProps) => {
  const { mild, moderate, severe, distribution, isSpectrumPage, comfortCare, children } = props;
  let { nonSurvival } = props;
  let dist = comfortCare ? buildArray100(0, 0, 0, 100) : buildArray100(mild, moderate, severe, nonSurvival);
  const survive = mild + moderate + severe;
  nonSurvival = nonSurvival || 100 - survive;

  if (distribution === 'random') {
    shuffleArray(dist);
  }

  if (distribution === 'blocks' && survive < 60) { // block distribution doens't work for large survival numbers
    const mildRows = computeRowCount(mild, true);
    const moderateRows = computeRowCount(moderate);
    const severeRows = computeRowCount(severe);
    const remainingRows = 10 - mildRows - moderateRows - severeRows;

    dist = [
      ...distByRow(mildRows, mild, 'mild'),
      ...distByRow(moderateRows, moderate, 'moderate'),
      ...distByRow(severeRows, severe, 'severe'),
      ...Array(remainingRows * 10).fill('nonSurvival')
    ];
  }

  return (
    <div className="ia-container">
      <div className="iaGrid">
        {
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => (
            <div className="row" key={i}>
              {
                dist.slice(i * 10, i * 10 + 10).map((e, j) => <div className={`${e} elem`} key={`${e}-${i * 10 + j}`} />)
              }
            </div>
          ))
        }
      </div>
      {
        comfortCare
          ? (
            <div className="legend">
              <Legend name="nonSurvival" count={100} isSpectrumPage={isSpectrumPage} />
            </div>
          )
          : (
            <div className="legend">
              { children }
              <Legend name="mild" count={mild} isSpectrumPage={isSpectrumPage} />
              <Legend name="moderate" count={moderate} isSpectrumPage={isSpectrumPage} />
              <Legend name="severe" count={severe} isSpectrumPage={isSpectrumPage} />
              <Legend name="nonSurvival" count={nonSurvival} isSpectrumPage={isSpectrumPage} />
            </div>
          )
      }
    </div>
  );
};

IconArray.defaultProps = { nonSurvival: null, distribution: null, isSpectrumPage: false, comfortCare: false };

export default IconArray;
