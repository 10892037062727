// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NextButton from './GrayableButton';
import AudioManager from './AudioManager';
import triangle from '../../images/Triangle_Button.png';

type NavBarProps = {
  next: string,
  previous: string,
  grayNext: boolean,
  audioManagerPauseButtonRef: any,
  audioManagerPlayButtonRef: any,
  locked: boolean,
  navigation: {
    next: string,
    back: string
  },
  additionalClickHandler: any => void | undefined
};

const NavBar = ({
  next = '',
  previous,
  grayNext = false,
  audioManagerPauseButtonRef,
  audioManagerPlayButtonRef,
  locked = false,
  navigation,
  additionalClickHandler
}: NavBarProps) => (
  <nav className="hidden-print">
    {
      previous
        ? (
          <Link to={previous} className="nav-text nav-left">
            <img src={triangle} alt="left-triangle" className="nav-triangle flipped" />
            &nbsp;
            { navigation.back }
          </Link>
        ) : <div />
    }

    <AudioManager
      audioManagerPauseButtonRef={audioManagerPauseButtonRef}
      audioManagerPlayButtonRef={audioManagerPlayButtonRef}
      locked={locked}
      additionalClickHandler={additionalClickHandler}
    />

    { next
      ? (
        <NextButton
          grayNext={grayNext}
          to={next}
          nav
        >
          { navigation.next }
          &nbsp;
          <img key="triangle" src={triangle} alt="right-triangle" className="nav-triangle" />
        </NextButton>
      ) : <div className="flex-placeholder" /> }
  </nav>
);

const mapStateToProps = ({ content: { navigation } }) => ({ navigation });

export default connect(mapStateToProps)(NavBar);
