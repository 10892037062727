// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navPathsFunc } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import InstructionPage from '../pageAbstraction/InstructionPage';
import pageAudioActions from '../../actions/audioActions';
import type { PageProps } from '../../lib/types';

const pageName = 'ComfortCareIntro';

const [previous, next] = navPathsFunc(pageName);

const ComfortCareIntro = ({ updateTrack, pauseAudio }: PageProps) => {
  useEffect(() => {
    updateTrack('CCIntro');

    return pauseAudio;
  }, []);

  return (
    <InstructionPage page={pageName}>
      <NavBar next={next} previous={previous} />
    </InstructionPage>
  );
};

const mapDispatchToProps = pageAudioActions;

export default connect(null, mapDispatchToProps)(ComfortCareIntro);
