import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../lib/store';
import { locale } from '../lib/utils';
import pageOrder from '../lib/pageOrder';
import history from '../lib/history';
import './App.scss';

const componentObj = {};
pageOrder.forEach(page => {
  componentObj[page] = require(`./pages/${page}.jsx`).default;
});

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <div className={`app ${locale}`}>
        <Switch>
          {/* English routes */}
          <Route exact path="/" render={() => (<Redirect to={`/en/${Object.keys(componentObj)[0].toLowerCase()}`} />)} />
          {
            Object.keys(componentObj).map(key => (
              <Route path={`/en/${key.toLowerCase()}`} component={componentObj[key]} key={key} />
            ))
          }
          {/* Spanish routes */}
          <Route exact path="/es" render={() => (<Redirect to={`/es/${Object.keys(componentObj)[0].toLowerCase()}`} />)} />
          {
            Object.keys(componentObj).map(key => (
              <Route path={`/es/${key.toLowerCase()}`} component={componentObj[key]} key={key} />
            ))
          }
        </Switch>
      </div>
    </Router>
  </Provider>
);

export default App;
