// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navPathsFunc } from '../../lib/utils';
import HandsPage from '../pageAbstraction/HandsPage';
import NavBar from '../reusables/NavBar';
import pageAudioActions from '../../actions/audioActions';
import type { PageProps } from '../../lib/types';
import './PersonalValues.scss';

const pageName = 'PersonalValues';

const [previous, next] = navPathsFunc(pageName);

const PersonalValues = ({ updateTrack, pauseAudio }: PageProps) => {
  useEffect(() => {
    updateTrack('ValuesIntro');

    return pauseAudio;
  }, []);

  return (
    <HandsPage pageName={pageName} >
      <NavBar next={next} previous={previous} />
    </HandsPage>
  );
};

const mapDispatchToProps = pageAudioActions;

export default connect(null, mapDispatchToProps)(PersonalValues);
