// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pageAudioActions from '../../actions/audioActions';
import SurveyQuestion from '../reusables/SurveyQuestion';
import surveyActions from '../../actions/surveyActions';
import { markdown, navPathsFunc } from '../../lib/utils';
import NavBar from '../reusables/NavBar';
import './Survey.scss';
import type { SpectrumPageProps, DueDateObj } from '../../lib/types';

type SurveyProps = SpectrumPageProps & {
  resetChances: void => ({ type: string }),
  dueDateObj: DueDateObj
}

const [previous, next] = navPathsFunc('Survey');

const Survey = ({ chances, dueDateObj, /* resetChances, */ content, updateTrack, pauseAudio }: SurveyProps) => {
  // const grayReset = dueDateObj.choice === '';
  const isHidingWeeksPregnant = dueDateObj.choice !== '' && dueDateObj.choice !== 'weeksPregnant';
  const isHidingDueDate = dueDateObj.choice !== '' && dueDateObj.choice !== 'dueDate';

  useEffect(() => {
    updateTrack('Calculator');

    return pauseAudio;
  }, []);

  return (
    <div className="background plain">
      <div className="survey content">
        <article>
          <h2>{content.Survey.header}</h2>

          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: markdown(content.Survey.markdown) }}
          />

          <form>
            <div
              className="markdown"
              dangerouslySetInnerHTML={{ __html: markdown(content.Survey.instructionsMarkdown) }}
            />
            <div className="flex-container">
              <SurveyQuestion
                className="left"
                key={`survey-q-${0}`}
                index={0}
                inputType="dropdown"
                reduxActionType="weeksPregnant"
                hidden={isHidingWeeksPregnant}
              />

              { !dueDateObj.input && <p className="divider">{content.Survey.or}</p> }

              <SurveyQuestion
                className="right"
                key={`survey-q-${1}`}
                index={1}
                inputType="date"
                reduxActionType="dueDate"
                hidden={isHidingDueDate}
              />
            </div>
            {/* <ResetButton grayNext={grayReset} onClick={grayReset ? null : resetChances}>
              { content.Survey.reset }
            </ResetButton> */}
          </form>
        </article>

        <NavBar next={next} previous={previous} grayNext={chances.nonsurvival === 0} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ chances, dueDateObj, content }) => ({ chances, dueDateObj, content });
const mapDispatchToProps = { ...surveyActions, ...pageAudioActions };

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
