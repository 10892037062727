import { combineReducers } from 'redux';
import chances from './chancesReducer';
import dueDateObj from './dueDateReducer';
import values1TextArr from './values1TextReducer';
import valuesScoreArr from './valuesScoreReducer';
import content from './contentReducer';
import audio from './audioReducer';

export default combineReducers({
  chances,
  values1TextArr,
  valuesScoreArr,
  content,
  dueDateObj,
  audio,
});
