// @flow

import React from 'react';
import { Link } from 'react-router-dom';

type GrayableButtonProps ={
  to: string | null,
  grayNext: boolean,
  nav: boolean,
  onClick: null | React.React.SyntheticEvent => void,
  children: React.Node<*>,
}

const GrayableButton = ({ grayNext, to = null, nav = false, onClick = null, children }: GrayableButtonProps) => {
  let Component;

  if (!nav) Component = () => <span type="button" />;
  else if (grayNext) Component = () => <div className="flex-placeholder" />;
  else Component = Link;

  const className = nav ? 'nav-text nav-right' : 'reset-button';
  const buttonStyle = grayNext ? { color: '#ABA095', fontSize: '0.9em' } : {};

  return (
    <Component
      to={to}
      className={className}
      style={buttonStyle}
      onClick={onClick}
    >
      { children }
    </Component>
  );
};

export default GrayableButton;
