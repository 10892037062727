import { applyMiddleware, createStore } from 'redux';
import mainReducer from '../reducers';

let middleware = [];
if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger');
  middleware = [...middleware, createLogger()];
}

export default createStore(mainReducer, {}, applyMiddleware(...middleware));
