// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { ContentShape } from '../../lib/types';

type LegendProps = {
  name: string,
  count: number,
  isSpectrumPage: boolean,
  content: ContentShape
}

const Legend = ({ name, count, isSpectrumPage, content }: LegendProps): React.HTMLHeadingElement | null => {
  if (count < 1) return null;

  return (
    isSpectrumPage
      ? <h6 className={`${name} coloredHeading`}>{`${count} ${content.spectrumPage}`}</h6>
      : <h6 className={name}>{`${count} ${content.Outcomes.option1.results.legend[name]}`}</h6>
  );
};

const mapStateToProps = ({ content }) => ({ content });

export default connect(mapStateToProps)(Legend);
