// @flow

import { localeFunc } from '../lib/utils';

const initialState = {
  mild: 88,
  moderate: 4,
  severe: 8,
  nonsurvival: 0,
};

const chancesReducer = (state = initialState, action) => {
  const chances = localeFunc().Survey.data;

  switch (action.type) {
    case 'WEEKS_PREGNANT_CHANGE':
      return {
        mild: chances[action.value][0],
        moderate: chances[action.value][1],
        severe: chances[action.value][2],
        nonsurvival: chances[action.value][3],
      };

    case 'DUE_DATE_CHANGE':
      if (!action.value) return initialState;
      return {
        mild: chances[action.value][0],
        moderate: chances[action.value][1],
        severe: chances[action.value][2],
        nonsurvival: chances[action.value][3],
      };

    case 'RESET_APP':
      return initialState;

    case 'RESET_CHANCES':
      return initialState;

    default:
      return state;
  }
};

export default chancesReducer;
