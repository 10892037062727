// @flow

import { localeFunc } from '../lib/utils';

const content = localeFunc();
const list1 = content.ValuesSurvey1.questionList;
const list2 = content.ValuesSurvey2.questionList;

const initialState = new Array(list1.length + list2.length + 3).fill(0);

const scoreReducer = (state = initialState, action) => {
  const scoresArr = state.slice();

  switch (action.type) {
    case 'SLIDER_SCORE_CHANGE':
      scoresArr[action.index] = action.value;
      return scoresArr;
    case 'RESET_APP':
      return initialState;
    default:
      return state;
  }
};

export default scoreReducer;
