// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NavBar from '../reusables/NavBar';
import IconArray from '../IconArray/IconArray';
import { markdown, navPathsFunc, locale } from '../../lib/utils';
import './ComfortCare.scss';
import pageAudioActions from '../../actions/audioActions';
import type { SpectrumPageProps } from '../../lib/types';

const pageName = 'ComfortCare';

const [previous, next] = navPathsFunc(pageName);

const ComfortCare = ({ chances, content, updateTrack, pauseAudio }: SpectrumPageProps) => {
  useEffect(() => {
    updateTrack('CC');

    return pauseAudio;
  }, []);

  return (
    <div className={`background severity ${pageName.toLowerCase()}`}>
      <div className="content">
        <article>
          <h2 className="coloredHeading">{content[pageName].header}</h2>
          <div className="show">
            <IconArray mild={chances.mild} moderate={chances.moderate} severe={chances.severe} nonSurvival={chances.nonsurvival} isSpectrumPage comfortCare distribution="linear" />
          </div>
          <section
            className="body"
            dangerouslySetInnerHTML={{ __html: markdown(content[pageName].markdown) }}
          />
        </article>
        <div className="video-and-text">
          <div className="video">
            <iframe
              title="comfort-care-video"
              src={locale === 'es' ? 'https://bcove.video/3clGb1L' : 'https://bcove.video/30R5a57'}
              allowFullScreen
              allow="autoplay; encrypted-media"
              width="480"
              height="270"
            />
          </div>
          <div className="video-text">
            <h3 className="coloredHeading">{content[pageName].story.heading}</h3>
            <p>{content[pageName].story.text}</p>
          </div>
        </div>
        <NavBar next={next} previous={previous} />
        <div className="top-right-corner">
          <h5 className="orange-text comfort-care">
            {content[pageName].type}
          </h5>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ chances, content }) => ({ chances, content });

const mapDispatchToProps = pageAudioActions;

export default connect(mapStateToProps, mapDispatchToProps)(ComfortCare);
